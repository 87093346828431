import { Box, Button, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserDetailsProvider, useUserDetails } from '../../../../application/context/UserDetailsContext';
import { theme } from '../../../../theme';
import { isDefined } from '../../../../utils/type-utils';
import ConfirmModal from '../../FloatingStuff/ConfirmModal';
import UserCard from '../UserCard/UserCard';
import UserDetailsTable from './UserDetailsTab';
import { useNavigate } from 'react-router';
import { ApplicationRoutes } from '../../../../application/constants/navigation';

const UserDetails = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const UserDetailsDisplay = (): JSX.Element => {
    const {
      userDetails,
      isPending,
      isError,
      isSuccess,
      unbanMutation,
      banMutation,
      confirmModalOpen,
      modalText,
      handleBanUnBanButtons,
      handleConfirmModal,
      closeConfirmModal,
    } = useUserDetails();

    if (isError) {
      navigate(ApplicationRoutes.NO_RESULTS);
    }

    return (
      <>
        {isPending && <Typography>is loading</Typography>}
        {isSuccess && isDefined(userDetails) && (
          <>
            <Box
              sx={{
                p: theme.spacing(3),
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <UserCard
                src={userDetails.profilePictureUrl}
                topLineText={`${t('USER.AVATAR.PRETEXT')}: ${userDetails.vcId}`}
                bottomLineText={userDetails.email}
              />
              {userDetails.isBanned ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {isDefined(userDetails.banExpiryDate) && (
                    <Typography
                      sx={{
                        alignContent: 'center',
                        marginRight: theme.spacing(1),
                      }}
                    >
                      {t('USER.BANUNTIL', {
                        banEndDate: userDetails.banExpiryDate.toLocaleDateString(),
                      })}
                    </Typography>
                  )}
                  <Button
                    onClick={(e) => handleBanUnBanButtons(e, false)}
                    variant='outlined'
                    disabled={unbanMutation.isPending}
                  >
                    {t('USER.CTA.UNBAN')}
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={(e) => handleBanUnBanButtons(e, true)}
                  variant='outlined'
                  color='error'
                  disabled={banMutation.isPending}
                >
                  {t('USER.CTA.BAN')}
                </Button>
              )}
            </Box>
            <Divider sx={{ marginBottom: theme.spacing(2) }} />
            <Box sx={{ p: theme.spacing(3) }}>
              <Typography sx={{ textDecoration: 'underline', fontWeight: 'Bold' }}>
                <span style={{ cursor: 'pointer' }}>{t('USER.TAB.PERSONALINFO')}</span>
              </Typography>
            </Box>
            <Box sx={{ p: theme.spacing(3) }}>
              <UserDetailsTable userInfos={userDetails}></UserDetailsTable>
            </Box>
            <ConfirmModal
              open={confirmModalOpen}
              text={modalText}
              onCancel={() => {
                closeConfirmModal();
              }}
              onConfirm={() => {
                handleConfirmModal();
              }}
            />
          </>
        )}
      </>
    );
  };

  return (
    <UserDetailsProvider>
      <UserDetailsDisplay />
    </UserDetailsProvider>
  );
};

export default UserDetails;
