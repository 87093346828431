import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { authService } from '../services/auth/AuthService';
import { ApplicationRoutes, AuthRoutes } from '../constants/navigation';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async (): Promise<void> => {
      try {
        await authService.handleCallback();
        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get('state');
        if (state) {
          const { referralPage } = JSON.parse(state);
          navigate(referralPage);
        } else {
          console.error('State parameter is missing or invalid');
          navigate(ApplicationRoutes.ROOT);
        }
      } catch (error) {
        console.error('Error handling auth callback:', error);
        navigate(AuthRoutes.ERROR);
      }
    };

    handleAuthCallback();
  }, [navigate]);

  return <div>Loading...</div>;
};

export default AuthCallback;
