import { FormHelperText, InputAdornment, OutlinedInput, SxProps, Theme } from '@mui/material';
import { FC, useState, FormEvent } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router';
import { Close } from '@mui/icons-material';
import { SearchKeywordType, typeToSearch } from '../../../../utils/search-utils';
import { theme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import { RouteParts } from '../../../../application/constants/navigation';

type SearchBarProps = {
  sx?: SxProps<Theme>;
};

const SearchBar: FC<SearchBarProps> = ({ sx }) => {
  const [valueToSearch, setValueToSearch] = useState('');
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    setHasError(false);

    const trimmedValue = valueToSearch.trim();
    switch (typeToSearch(trimmedValue)) {
      case SearchKeywordType.USER_ID:
      case SearchKeywordType.EMAIL:
        navigate(`/${RouteParts.USERS}/${trimmedValue}`);
        break;
      case SearchKeywordType.CHANNEL_ID:
        navigate(`/${RouteParts.CONVERSATIONS}/${trimmedValue}`);
        break;
      default:
        setHasError(true);
        break;
    }
  };

  const handleClear = (): void => {
    setValueToSearch('');
    setHasError(false);
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <OutlinedInput
          sx={{
            ...sx,
            p: theme.spacing(0, 1),
            background: theme.palette.grey[200],
            height: theme.spacing(6),
          }}
          id='search-field-sidebar'
          value={valueToSearch}
          error={hasError}
          onChange={(e) => setValueToSearch(e.target.value)}
          placeholder={t('SIDEBAR.SEARCHBAR.PLACEHOLDER')}
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon color={hasError ? 'error' : 'inherit'} />
            </InputAdornment>
          }
          endAdornment={
            valueToSearch.length > 0 && (
              <InputAdornment position='end'>
                <Close onClick={() => handleClear()} sx={{ cursor: 'pointer' }} />
              </InputAdornment>
            )
          }
        />
      </form>
      <FormHelperText error={hasError}>
        {hasError ? t('SIDEBAR.SEARCHBAR.ERROR.EMAIL') : t('SIDEBAR.SEARCHBAR.SUBTEXT')}
      </FormHelperText>
    </>
  );
};

export default SearchBar;
