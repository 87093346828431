import { Box } from '@mui/material';
import ConversationsLayout from '../components/Layout/Conversations/ConversationsLayout';
import { FC } from 'react';
import { ChannelProvider } from '../../application/context/ChannelContext';

const ConversationsPage: FC = () => {
  return (
    <ChannelProvider>
      <Box className='ConversationsPage' sx={{ height: '100%' }}>
        <ConversationsLayout />
      </Box>
    </ChannelProvider>
  );
};

export default ConversationsPage;
