import React, { createContext, useContext, useEffect, useState } from 'react';
import { FCWithChildren } from '../../infrastructure/types/global';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { isDefined } from '../../utils/type-utils';
import { getUserByEmailOrId } from '../../core/usecases/getUserByEmailOrId';
import { useParams } from 'react-router';
import { User } from '../../core/entities/User';
import { useTranslation } from 'react-i18next';
import { useToaster } from './ToasterContext';
import { banUser, unbanUser } from '../services/UserService';
import ApiError from '../services/error/ErrorService';

interface UserDetailsContextProps {
  userDetails: User | null;
  isPending: boolean;
  isError: boolean;
  isSuccess: boolean;
  unbanMutation: UseMutationResult<User, Error, User, unknown>;
  banMutation: UseMutationResult<User, Error, User, unknown>;
  confirmModalOpen: boolean;
  modalText: string;
  handleConfirmModal: () => void;
  closeConfirmModal: () => void;
  handleBanUnBanButtons: (e: React.MouseEvent<HTMLElement>, isBan: boolean) => void;
}

const UserDetailsContext = createContext<UserDetailsContextProps | undefined>(undefined);

export const UserDetailsProvider: FCWithChildren = ({ children }) => {
  const { t } = useTranslation();
  const toaster = useToaster();
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState<User | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modalText, setModalText] = useState(t('USER.BAN.CONFIRM'));

  const { isPending, isError, isSuccess, mutate } = useMutation({
    mutationFn: () => getUserByEmailOrId(userId),
    onSuccess: (a) => {
      setUserDetails(a);
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });

  const banMutation = useMutation({
    mutationFn: (banTarget: User) => banUser(banTarget),
    onSuccess: (a) => {
      setUserDetails(a);
      toaster.success(t('USER.BAN.TOASTER.SUCCESS'));
    },
    onError: (a) => {
      if (a instanceof ApiError) {
        if (toaster.defaultErrorsHandling(a.status, 'User')) {
          return;
        } else if (a.status === 409) {
          toaster.error(t('USER.BAN.TOASTER.ERROR.BANINPROGRESS'));
          return;
        }
        toaster.error(t('USER.BAN.TOASTER.ERROR.DEFAULT'));
      } else {
        toaster.error(t('USER.BAN.TOASTER.ERROR.DEFAULT'));
      }
    },
  });

  const unbanMutation = useMutation({
    mutationFn: (banTarget: User) => unbanUser(banTarget),
    onSuccess: (a) => {
      setUserDetails(a);
      toaster.success(t('USER.UNBAN.TOASTER.SUCCESS'));
    },
    onError: (a) => {
      if (a instanceof ApiError) {
        if (toaster.defaultErrorsHandling(a.status, 'User')) {
          return;
        } else if (a.status === 409) {
          toaster.error(t('USER.UNBAN.TOASTER.ERROR.BANINPROGRESS'));
          return;
        }
        toaster.error(t('USER.UNBAN.TOASTER.ERROR.DEFAULT'));
      } else {
        toaster.error(t('USER.UNBAN.TOASTER.ERROR.DEFAULT'));
      }
    },
  });

  const handleConfirmModal = (): void => {
    setConfirmModalOpen(false);
    if (!isDefined(userDetails)) {
      return;
    }

    if (!userDetails?.isBanned) {
      banMutation.mutate(userDetails);
    } else {
      unbanMutation.mutate(userDetails);
    }
  };

  const closeConfirmModal = (): void => {
    setConfirmModalOpen(false);
  };

  const handleBanUnBanButtons = (e: React.MouseEvent<HTMLElement>, isBan: boolean): void => {
    e.preventDefault();
    const modalText = isBan ? t('USER.BAN.CONFIRM') : t('USER.UNBAN.CONFIRM');
    setModalText(modalText);
    setConfirmModalOpen(true);
  };

  useEffect(() => {
    mutate();
  }, [mutate, userId]);

  return (
    <UserDetailsContext.Provider
      value={{
        userDetails,
        isPending,
        isError,
        isSuccess,
        unbanMutation,
        banMutation,
        confirmModalOpen,
        modalText,
        handleConfirmModal,
        closeConfirmModal,
        handleBanUnBanButtons,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};

export const useUserDetails = (): UserDetailsContextProps => {
  const context = useContext(UserDetailsContext);
  if (context === undefined) {
    throw new Error('useUserDetails must be used within a UserDetailsProvider');
  }
  return context;
};
