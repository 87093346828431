import { Box, Chip, Typography } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FC } from 'react';
import ConversationsBox from './ConversationsBox';
import ConversationsSideBar from './ConversationsSideBar';
import { useNavigate } from 'react-router';
import { useChannel } from '../../../../application/context/ChannelContext';
import { ApplicationRoutes } from '../../../../application/constants/navigation';
import { theme } from '../../../../theme';
import TopBar from '../TopBar/TopBar';
import { useTranslation } from 'react-i18next';

const ConversationsLayout: FC = () => {
  const navigate = useNavigate();
  const { error, loading, inReadonlyMode } = useChannel();
  const { t } = useTranslation();

  if (error) {
    navigate(ApplicationRoutes.NO_RESULTS);
  }

  return (
    <>
      {loading ? (
        <Typography>is loading</Typography>
      ) : (
        <>
          <TopBar>
            <Typography variant='h1'>
              {t('CONVERSATIONS.HEADER.TITLE')}
              {inReadonlyMode && (
                <Chip
                  sx={{ float: 'right' }}
                  icon={<RemoveRedEyeIcon sx={{ color: theme.palette.grey[900] }} />}
                  label={t('CONVERSATIONS.HEADER.READONLY.CHIP')}
                  color='default'
                  size='medium'
                  variant='outlined'
                />
              )}
            </Typography>
          </TopBar>
          <Box
            sx={{
              padding: theme.spacing(2),
              background: theme.palette.grey[50],
              height: '100%',
            }}
          >
            <Box
              sx={{
                background: 'white',
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'row',
                height: 'calc(100% - 84px)',
                overflow: 'scroll',
              }}
            >
              <ConversationsSideBar />
              <ConversationsBox />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default ConversationsLayout;
