import { Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../../../../theme';

type ConversationsChatTimestampProps = {
  timeToDisplay: Date;
};

const ConversationsChatTimestamp: FC<ConversationsChatTimestampProps> = ({ timeToDisplay }) => {
  return (
    <Typography
      sx={{
        fontSize: '16px',
        color: theme.palette.grey[700],
        lineHeight: '24px',
        textAlign: 'center',
        marginBottom: theme.spacing(1),
        textTransform: 'capitalize',
      }}
    >
      {timeToDisplay.toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })}
    </Typography>
  );
};

export default ConversationsChatTimestamp;
