export type Nullable<T> = T | null;

export type Optional<T> = Nullable<T> | undefined;

export type WithRequiredProp<T, P extends keyof T> = T & Required<Pick<T, P>>;

export const isDefined = <T>(value: T | undefined | null): value is T => value !== undefined && value !== null;

export function isNotEmptyString(value: Optional<string>): value is string {
  return typeof value === 'string' && value.length > 0;
}

export function isEmptyString(value: Optional<string>): boolean {
  return typeof value !== 'string' || value.length === 0;
}

export function isNotEmptyArray<T>(value: Optional<T[]>): value is T[] {
  return Array.isArray(value) && value.length > 0;
}
