import { BrowserRouter as Router, Route, Routes } from 'react-router';
import HomePage from './ui/pages/HomePage';
import ChatModLayout from './ui/components/Layout/ChatModLayout';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './application/context/AuthContext';
import UserProfilePage from './ui/pages/UserProfilePage';
import AuthCallback from './application/pages/AuthCallback';
import { ToasterProvider } from './application/context/ToasterContext';
import ConversationsPage from './ui/pages/ConversationsPage';
import VersionPage from './ui/pages/version/VersionPage';
import NoResultsPage from './ui/pages/NoResultsPage';
import ErrorComponent from './ui/components/helpers/error';
import { ApplicationRoutes, AuthRoutes } from './application/constants/navigation';

const App = (): JSX.Element => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ToasterProvider>
          <AuthProvider>
            <ChatModLayout>
              <Routes>
                <Route path={ApplicationRoutes.ROOT} element={<HomePage />} />
                <Route path={ApplicationRoutes.CONVERSATION_SINGLE} element={<ConversationsPage />} />
                <Route path={ApplicationRoutes.USERS} element={<UserProfilePage />} />
                <Route path={ApplicationRoutes.VERSION} element={<VersionPage />} />
                <Route path={ApplicationRoutes.NO_RESULTS} element={<NoResultsPage />} />
                <Route path={ApplicationRoutes.NOT_FOUND} element={<ErrorComponent errorCode={404} />} />
                <Route path={AuthRoutes.AUTH_CALLBACK} element={<AuthCallback />} />
              </Routes>
            </ChatModLayout>
          </AuthProvider>
        </ToasterProvider>
      </Router>
    </QueryClientProvider>
  );
};

export default App;
